
.mailing-list-title {
  margin-bottom: 0px;
}

.mailing-list-description {
  margin: 0px;
  margin-top: 12px;
  margin-bottom: 18px;
}
