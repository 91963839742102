@import "../../variables";

.map-background {
  background-image: url(/waves.png);
  position: absolute;
  left: 0;
  top: 0;
  height: 2000px;
  min-width: 100%;
  right: 0;
  // invert;
  // transform: inv;
  // filter: invert(1);
  opacity: 0.5;
}

.map-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: -1;
}

.map {
  position: relative;
  margin: 0;
  margin-top: 10vh;
  min-height: 100vh;
  width: 100%;
  text-align: center;
}

.map-svg {
  overflow: visible;
}

@media (max-width: 768px) {
  .map {
    margin-top: 48vh;
  }
}

.map-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 3em;
  font-weight: 700;
  letter-spacing: 0.4em;
  position: absolute;
  top: 21vh;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  z-index: 10;
}

@media (max-width: 768px) {
  .map-title {
    top: 15vh;
  }
}

.map-title span {
  display: inline-block;
}

.map-rotate {
  margin-left: -0.4em;
  margin-right: 0.4em;

  top: 0.1em;

  position: relative;
  display: inline-block;

  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.map-under-title {
  font-weight: 200;
  font-size: 1em;
  letter-spacing: 0.2em;
  text-align: center;
}

.map-clouds {
  animation: animate-clouds 45s linear 0s infinite;
  background-image: url(/images/cloudbg.png);
  pointer-events: none;
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  z-index: 3;
  background-repeat: repeat-x;
}

.map-clouds-2 {
  // animation: animate-clouds 90s linear 0s infinite;
  // animation-direction: reverse;
  transform: rotate(180deg);
  // animation-delay: -45s;
  transform: translateZ(0px);
  background-image: url(/images/cloudbg.png);
  pointer-events: none;
  position: absolute;
  z-index: 1000;
  min-height: 100%;
  min-width: 100%;
  // opacity: 0.4;
  z-index: 3;
  left: 0px;
  top: 0px;
  background-repeat: repeat-x;
}

@keyframes animate-clouds {
  from {
    background-position: 0px 0px;
  }

  to {
    background-position: 1781px 0px;
  }
}


.map-border {
  stroke-width: 0.5px;
  stroke: $textColor;
  // url(#gradient);
  fill: none;

  // stroke-linecap: round;
  // stroke-width: 1px;
  // stroke-dasharray: 0.1, 4;
}

// .map-border-active {
//   stroke: $textColor;
//   z-index: 2;
// }

// .map-border-inactive {
//   // display: none;
//   stroke: rgba(0, 0, 0, 0.1);
//   // z-index: 1;
// }

.map-border path {
  // stroke-dasharray: 5000;
  // stroke-dashoffset: 5000;
  // animation: draw-overtime 5s linear forwards;
  animation: draw-overtime 3.5s ease-out forwards;
}

@keyframes draw-overtime {
  to {
    stroke-dashoffset: 0;
  }
}

.map-journey-path path {
  fill: none;
  stroke-width: 2px;
}

// $completed-color: green;
// $inprogress-color: $completed-color;
// $todo-color: yellow;

// $completed-color: #6b76ff;
// $inprogress-color: #a5aeff;
// $todo-color: #c8e4fe;

$completed-color: url(#gradient);
$inprogress-color: url(#in-progress);
$todo-color: $lightgray;


.map-path-completed {
  stroke: url(#gradient);
  // stroke: $completed-color;
}

.map-path-inprogress {
  animation: inprogress-dash 20s linear infinite;
  stroke-dasharray: 2;
  stroke: $inprogress-color;
}

@keyframes inprogress-dash {
  to {
    stroke-dashoffset: -500;
  }
}

.map-path-todo {
  stroke: $todo-color;// yellow;
  // stroke-linecap: round;
  // stroke-width: 1px;
  // stroke-dasharray: 0.1, 4;
  stroke-dasharray: 4;
}

.map-journey-circle {
  // fill: none;
  fill: transparent;
  // fill-opacity: 0.3;
  stroke-width: 1px;
  // transform-origin: 50% 50%;
}
.map-journey-bubbles circle:hover {
  cursor: pointer;
}
.map-journey-bubbles {
  outline: none;
}

.map-journey-posts {
  outline: none;
}

.map-journey-post {
  font-size: 16px;
  fill: $textColor;
}

.map-marker-post-link {
  font-weight: bold;
  text-decoration: underline;
}
.map-marker-post-link:hover {
  cursor: pointer;
}

.map-journey-marker-line {
  fill: none;
  stroke: $textColor;
  stroke-width: 0.8px;
  // stroke-dasharray: 2;
}

.map-journey-post-text {
  font-size: 14px;
  font-weight: 300;
}

.marker-is-hovered {
  fill: black;
}

// .map-journey-post:before {
//   content: attr(data-title);
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 0px;
//   text-decoration: underline;
//   transition: all 0.3s ease 0s;
//   -webkit-transition: all 0.3s ease 0s;
//   text-decoration-color: $lightBlue;
//   overflow: hidden;
// }

// .marker-is-hovered {
//   &:before {
//     width: 100%;
//   }
// }

.map-journey-circle-inner {
  fill: $textColor;
  // fill-opacity: 0.3;
  // stroke-width: 1px;
  stroke: none;
  // filter: url(#dropshadow);
  // transform-origin: 50% 50%;
}
// .map-journey-circle-inner:hover {
//   // fill-opacity: 1;
//   // fill: white;
//   // transform: scale(0.8);
// }

.map-circle-completed {
  stroke: $completed-color;
}

.map-circle-todo {
  stroke: $todo-color;
  // stroke: $lightgray;
  // fill: $lightgray;
}


.map-journey-description text {
  stroke: none;
  fill: $textColor;
  font-size: 14px;
  font-weight: 300;
}

.map-post-to-be-continued {
  fill: #bbb;
  padding-left: 4px;
}