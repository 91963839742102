@import "../../variables";

.post-link {
  display: inline-block;
  overflow-wrap: break-word;
}

.post-text {
  line-height: 1.4em;
  margin: 14px 0px;
}

.post-image-container {
  width: 100%;
  text-align: center;

  // padding: 0px 6px;
  // margin: 6px;
}

// .post-image:not(:first-child):not(:last-child) {
//   padding: 0px 6px !important;
// }
.post-image-container {
  padding: 0px 6px;
}

// .post-image-container:first-child:not(:last-child) {
//   padding-left: 0px;
// }

// .post-image-container:first-child {
//   margin-left: -6px !important;
// }

// .post-image-container:last-child {
//   margin-right: -6px !important;
// }

.post-image-margin {
  margin-top: 16px;
  margin-bottom: 14px;
}

// .post-image-container:not(:first-child):not(:last-child) {
//   padding: 0px 6px !important;
// }

.post-image:hover {
  cursor: pointer;
}

.post-grid {
  margin: 6px 0px;
}

.post-image {
  max-width: 100%;
  // border: 1px solid $textColor;
}

.post-list {
  margin: 0;
}

.post-heading {
  margin-top: 16px;
}

.post-html-container {
  overflow-wrap: break-word;
}

.post-inline {
  display: inline;
}
