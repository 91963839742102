@import "../../variables";

.loader-container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.loader-container-with-margin {
  margin-top: 120px;
  margin-bottom: 200px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100%;
  position: relative;

  .spinner {
    width: 46px;
    height: 46px;
    border-radius: 100%;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // margin-top: 80px;
    // margin-bottom: 80px;

    border: 2px solid $lightBlue;
    border-radius: 0;
    animation: rotate 3s infinite;
    &:after, &:before{
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      width: 32px;
      height: 32px;
      border: 2px solid $textColor;
      animation: rotate 3s infinite reverse;
    }
    &:before{
      border-color: $lightBlue;
      left: 10px;
      top: 10px;
      width: 22px;
      height: 22px;
      animation: rotate 2s infinite;
    }
  }
}