@import "variables";

body {
  background-color: $backgroundColor;
  color: $textColor;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}
