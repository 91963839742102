@import "../../variables";

.header-links {
  margin-right: 20px;
  text-align: right;
}

.header-link {
  display: inline-block;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  padding: 0.4em;
  border: none;
  outline: none;
  margin: 20px;
  text-decoration: none;

  position: relative;

  &::after {
    border-bottom: 1px solid $textColor;

    bottom: 0;
    left: 0;

    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    width: 0;
    height: 2px;

    transition: 150ms all ease-out;
    -webkit-transition: 150ms all ease-out;
  }

  &:hover::after {
    border-bottom-color: $lightBlue; // Make borders visible
    width: 100%;
  }
}
.header-link:hover {
  cursor: pointer;
}

.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
}

.group-row {
  display: flex;
  align-items: center;
  width: 100%;

  > *:not(:first-child) {
    margin-left: 10px;
  }
}

.header-mobile-menu-burger {
  background: none !important;
  border: none;
  border-bottom: 15px double $textColor; 
  border-top: 5px solid $textColor; 
  border-radius: 0;
  content: "";
  outline: none;
  padding: 0;
  padding-top: 5px;
  box-shadow: none;
  height: 0; 
  width: 30px;
  margin-left: 20px;
  margin-top: 20px;

  display: none;
  visibility: none;
}

.header-mobile-menu-close {
  background: none !important;
  color: $textColor !important;
  font-size: 24px;
  margin-left: 6px;
  margin-top: 6px;

  position: fixed;

  display: none;
  visibility: none;

  z-index: 55;
}

.header-mobile-menu-title {
  margin-bottom: 20px;
  font-size: 26px;
}

.logo-link {
  text-decoration: none;
  position: absolute;
  margin: 0;
  top: 20px;
  left: 30px;
  text-align: left;
  font-size: 1.4em;

  span {
    display: inline-block;
  }

  // .character-rotate {
  //   font-size: 0.75em;
  //   margin-left: 0.05em;
  //   margin-right: 0.05em;
  //   // top: -0.15em;
  //   // top: 0.225em;
  //   top: 0.05em;
  //   position: relative;
  //   display: inline-block;
  //   transform: rotate(180deg);
  // }
}

/* Responsive Styles */

@media screen and (max-width : 800px) {
  .logo-link {
    left: 80px;
  }

	.header-link {
    border: none;
    border-bottom: 1px solid $textColor;
    border-top: 1px solid $textColor;
    margin: 0;
    margin-top: -1px;
    padding: 20px;
    font-size: 24px;
  }

  .header-links {
    position: fixed;
    border-right: 1px solid #282c34;
    top: 0;
    left: 0;
    // animation: slide-in-from-left 100ms ease-out;
    bottom: 0;
    margin: 0;
    background: $backgroundColor;
    color: $textColor;
    padding: 60px 0px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 50;
    height: 100vh;
    text-align: center;

    transition: all 0.1s ease;
    visibility: hidden;
    right: 100%;

  
    // > *:not(:last-child) {
    //   margin-bottom: 14px;
    // }
  }

  // .header-links-mobile-menu-closed {
  //   // transition: all 250ms ease-in-out;

  // }

  .header-links-mobile-menu-open {
    visibility: visible;
    right: 14%;
  }
  
	.header-link {
		width: 100%;
  }
  
	.header-mobile-menu-close {
    display: block;
    visibility: visible;
  }
  
  .header-mobile-menu-burger {
    display: block;
    visibility: visible;
  }
}