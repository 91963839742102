@import "../../variables";

.footer-divider {
  // 5188b3
  // background-image: linear-gradient(90deg, #5ce6df, violet, #fa7600);
  background-image: linear-gradient(90deg, #5ce6df, violet);

  // background-image: -webkit-gradient(linear,left top,right top,from(red),color-stop(orange),color-stop(#ff0),color-stop(green),color-stop(blue),color-stop(indigo),to(violet));
  // background-image: -webkit-linear-gradient(left,red,orange,#ff0,green,blue,indigo,violet);
  // background-image: -o-linear-gradient(left,red,orange,#ff0,green,blue,indigo,violet);
  // background-image: linear-gradient(90deg,red,orange,#ff0,green,blue,indigo,violet);
  height: 1px;
  position: relative;
  width: 100%;
  border-radius: 1px;
  opacity: 0.5;
}

.footer-content {
  padding-top: 14px;
}

.footer-link {
  font-weight: 300;
  float: left;
  clear: left;
  margin-bottom: 10px;
}

.footer-link-right {
  float: right;
  clear: right;
}

@media screen and (max-width : 800px) {
  .footer-link {
    border: none;
    font-weight: 300;
    margin: 10px 0px;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
  }

  .footer-link-right {
    float: left;
    clear: left;
  }
}