@import "../../variables";

.home-line {
  background: linear-gradient(to bottom, $nice-white, $white) no-repeat 0 0;

  -webkit-animation: bikesouth 60s ease-out;
  -moz-animation: bikesouth 60s ease-out;
  -o-animation: bikesouth 60s ease-out;
  -ms-animation: bikesouth 60s ease-out;
  animation: bikesouth 60s ease-out;

  position: fixed;
  top: 0;
  left: 20%;
  bottom: 0;

  width: 2px;
}

@keyframes bikesouth {
  0% { background-size: 4px 0%; }
  100% { background-size: 100vh 100%; }
}
