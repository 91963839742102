@import "../../variables";

.posts-post {
  display: flex;
}

.posts-description {
  text-decoration: none;
  font-weight: 300;
  margin: 0;
  margin-bottom: 24px;
  margin-top: 6px;
  font-size: 14px;
}

.posts-desc-date {
  color: #999;
}

.posts-title {
  display: inline-block;
  vertical-align: top;
}

.posts-thumbnail-container {
  display: inline-block;
  max-width: 200px;
  max-height: 200px;
  width: 30%;
  margin-right: 14px;
  margin-bottom: 24px;
}

.posts-thumbnail {
  // border: 1px solid black;
  // border-top-left-radius: 2px;
  // border-bottom-left-radius: 2px;
  // display: inline-block;
  width: 100%;
}

.posts-thumbnail:hover {
  cursor: pointer;
}