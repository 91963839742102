$black: #060606; // #000000; // Sofloo uses this black I like: `#282c34`.
$red: red;
$white: white;
$mediumgray: darken($white, 30%);
$lightgray: darken($white, 20%);
$verylightgray: darken($white, 20%);
$superlightgray: darken($white, 5%);
$darkgray: lighten($black, 40%);
$blue: #2684ff;
$lightBlue: #5ce6df;
$darkblue: darken($blue, 20%);

$nice-white: #F1F3FC;

// Cloud white #ecf0f1

$raisedShadow: 0 5px 20px $darkgray;

$inputHeight: 36px;

$backgroundColor: #FCFCFC;
$textColor: #282c34;


// #F1F3FC